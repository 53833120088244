
*{
    box-sizing: border-box;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
}

.logo_container{
    margin-left: 2%;
}
.logo{
    font-size: 50px;
    cursor: pointer;
}
.nav_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-weight: 600;
    background-color:antiquewhite;
    background-image: url('../images/world_map.jpg');
    background-position: center;
    padding-top: 0%;
    padding-bottom: 0%;
    margin-bottom: 0%;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1;
}

.nav_header_item_container{
    /* background-color: red; */
    width: 60%;
}

.nav_header_item{
    /* margin-left: 2%;
    margin-right: 2%; */
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;  
    padding-left: 2%;
    padding-right: 2%;  
    /* transition: all 0.3s;  */
}
.nav_header_item:hover{
    text-decoration: underline;
    /* font-weight: 700; */
}

.burger_menu{
    display: none;
}

.widgets{
    display: block;
}

.widgets_mobile{
    display: none;
}

.btvs_logo{
    width: 8%;
    cursor: pointer;
}
/* Different media screen */

@media screen and (max-width: 500px) {
    .burger_menu{
        display: block;
        margin-top: 6px;
        margin-left:38%;
        background: white;
        width: 12%;
        /* height: 47px; */
        padding-top: 1%;
    } 

    .btvs_logo{
        width: 20%;
    }
 
    .burger_menu img {
        cursor: pointer;
    }

    .nav_header{
        justify-content: center;
    }



    .nav_header_item_container{
        display: none;
       
        
    }

    .widgets{
        display: none;
    }

    .widgets_mobile{
        display: inline-block;
        /* margin: auto;
        margin-left: 37%; */
    }

    /* @keyframes example {
        from {width: 200px;}
        to {width: 400px;}
      } */

    /* .burger_menu_container{
        top: 5px;
        background-color: red;
        height: 300px;
        width: 250px;
        position: absolute;
        display: block;
        transition: width 1s ease-in-out;
        animation-name: example;
        animation-duration: 2s;
    } */


}

@media screen and (max-width: 800px) {
    .btvs_logo{
        width: 20%;
    }
}