:root {
    --font-color: #333;
    --background-color: #ffffff;
    --footer-background-color :#e2cbac;
    --link-color: cornflowerblue;
  }

  html{
    scroll-behavior: smooth;
  }
  
/* [data-theme="dark"] {
    --font-color: #eee;
    --background-color: #616161;
    --footer-background-color :#616161;
    --link-color: lightblue;
  } */
.loader{
    /* min-height:400px; */
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;
    margin: auto;
    align-items: center;
    justify-content: center;
}
*{
    box-sizing: border-box;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
}

.page_header{
    margin-bottom: 4%;
    margin-top: 0%;
    font-size: 24px;
    padding-top : 6%;
    text-decoration: underline;
}

.main_container{
    padding-left: 0%;
    padding-right: 0%;
    margin-top: 4.5%;
    padding-top: 1%;
    background-color: var(--background-color);
    min-height: 640px;

}
.newsDetail_container{
    margin-top: 0%;
    padding-top: 2%;
}
.main_container_newsDetail{
    padding-left: 0%;
    padding-right: 0%;
    margin-top: 20%;
    background-color: var(--background-color);
    /* background-color: red; */
    margin: auto;
    /* margin-top: 30%; */
    width: 65%;
    text-align: left;
    margin-bottom: 8%;
    padding-bottom: 5%;
    margin-left: 5%;
}
.category_shareLink{
    /* width: 70%; */
    /* background: brown; */
    margin: auto;
    display: flex;
    /* justify-content: space-between;
    align-items: baseline; */
    margin-top: 4%;
    margin-left: 5%;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.circular_loading{
    margin-left: 50%;
    padding-top: 15%;
}

.share_platform

{
    cursor: pointer;
    margin-top: 2%;
    margin-left: 81%;
}

.card_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.whatsApp{
    height: 35px;
}
.card_item{
    /* margin-right: 4%; */
    margin-top: 2%;
    margin-bottom: 2%;
    cursor: pointer;
    transition: all .3s;
}

.news_content{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.topic_content > p {
    font-size: 24px;
    line-height: 1.8;
}

.topic_title
{
    width: 92%;
    padding-top: 2%;
    padding-bottom: 1%;
    font-size: 26px;
    line-height: 1.5;
}
.topic_author,
.topic_author{
    padding-top: 1%;
    padding-bottom: 0%;
}
.topic_image{
    padding-top: 3%;
    padding-bottom: 5%;
}


.news_topic_select{
    margin-top: 5%;
    margin-bottom: 5%;
}
.textEditor{
    width: 900px;
    margin: auto;
    border: 1px solid black;
    height: 400px;

}
.user_account{
    margin-top: 22%;
}

.editor_container{
    width: 90%;
    /* margin-top: 23%; */
    margin-bottom: 10%;
    margin-left: 6%;
}


.log_in{
    width: min-content;
    padding: 5%;
    /* background: red; */
    /* margin-left: 34%; */
    margin: auto;   
    margin-top: 2%;
    border: 1px solid gray;
    border-radius: 10px;
}
.log_in input{
    margin-bottom: 5%;
    width: 300px;
    padding: 12px;
}

.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }

  .link_style{
    text-decoration: none;
    background: rgb(203 217 232);
    margin-left:3%
  }

  .LoadMoreBtn,.ThatsAllBtn{
    margin-top: 2%;
  }

.footer{
        /* width: 100%;    
    position: relative; */
    background: var(--footer-background-color);
    border-top: 1px solid #ccc;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-top:5%;
}

.footer_links{
    padding-right: 2%;
    padding-left: 2%;
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.footer_links:hover{
    text-decoration: underline;
}

.termsandcondition,.privacy_policy{
    margin-top: 15%;
    margin-left: 5%;
    margin-right: 5%;
}

.terms_conditions_ul,
.ul_privacy_policy{
    list-style-type: none;
}

.heading{
    margin-top: 2%;
    margin-bottom: 2%;
}

.footer_main{
    margin-top: 5%;
}

.back_to_top{
    position: fixed;
    top: 85%;
    left: 86%;
    background: #817a7a;
    color: #d4cdcd;
    padding: 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition-duration: 0.5s;
    visibility: visible;
    opacity: 1;
}

.hidden{
    opacity: 0;
}

.back_to_top:hover{
    color: white;
    background-color: #333;
}
.social_share{
    text-align: right;
    margin-right: 15%;
    margin-top: 3%;
}
p > img{
    width: 65%;
}
.upArrow{
    position: fixed;
    top: 85%;
    left: 86%;
    width: 6%;
    visibility: hidden;
}


/* $back-to-top__button-color: deeppink;
$back-to-top__icon-color: white; */

.back-to-top {
  background-color: deeppink;
  text-decoration: none;
  cursor: pointer;
  width: 56px;
  height: 56px;
  z-index: 999;
  position: fixed;
  right: 32px;
  bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 4px 16px 0 rgba(black,0.4);
   }
  .back-to-top:hover {
    background-color: lighten(deeppink, 12%);
  }
  
  .back-to-top:active {
    box-shadow: none;
    background-color: darken(deeppink, 12%);
  }
  
  svg.icon__arrow-up {
    /* // Put these properties inline in the SVG
    // if you won't change these all the time */
    fill: deeppink;
    width: 32px;
    height: 32px;
  }





/* media queries */
@media only screen and (max-width: 800px) {
    body {
      /* background-color: rgb(224 227 228); */
      font-size: 14px;
    }

    .newsDetail_horizontal_ad{
        margin-top: 15%;
    }

    .btvs_logo{
        width: 20%;
    }

    .page_header{
        margin-bottom: 6%;
        padding-top: 10%;
    }

    .main_container{
        margin-top: 19%;
    }
    .whatsApp
    {
        height: 25px;
    }
    .newsDetail_container{
        margin-top: 12%;
        padding-top: 0%;
    }
    
    .circular_loading{
        margin-left: 45%;
        margin-top: 50%;
    }

    .card_item{
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
.termsandcondition,.privacy_policy{
    margin-top: 30%;

}

.back_to_top{
    font-size: 10px;
    padding: 8px;
    border-radius: 15px;
    visibility: hidden;
}

.upArrow{
    position: fixed;
    top: 82%;
    left: 86%;
    width: 10%;
    visibility: visible;
}


}

