*{
    margin: 0;
    padding: 0;
}

.nav_header_item_mobile{
    margin-top: 3%;
    margin-bottom: 3%;
    display: none;
}
.closeIconContainer{
    display: none;
}

@media screen and (max-width: 500px) {
    
    .burger_menu_container{
        left: 0px;
        top: 0px;
        background-color: #dac9c9;
        background-image: url('../images/bharat1.webp');
        background-repeat: no-repeat;
        height: 200vh;
        width: 100%;
        position: absolute;
        display: block;
        transition: width 1s ease-in-out;
        padding-top: 3%;
        z-index: 999;
        /* animation-name: example;
        animation-duration: 2s; */
    }

    .active_menu{
        transform: translate(0px,96vh);
    }

    .nav_header_item_container_mobile{
        display: flex;
        flex-direction: column;
        margin-bottom: 5%;
    }
    .nav_header_item_mobile{
        margin-top: 3%;
        margin-bottom: 3%;
        display: block;
        color: #ffffff;
    }

    .closeIconContainer{
        display: block;
        margin-bottom: 10%;
        margin-top: 8%;
    }
    .closeIconContainer img{
        cursor: pointer;
    }
}